

const toggleSidebar = () => {

  const open = document.getElementById('open-sidebar')
  const close = document.getElementById('close-sidebar')

  if (open) {
    open.addEventListener('click', (e) => {
      toggleClasses();
    })
  }
  if (close) {
    close.addEventListener('click', (e) => {
      toggleClasses();
    })
  }
}

const toggleClasses = () => {
  const sidebarNav = document.getElementById('sidebar-nav')
  console.log('toggling class')
  if (sidebarNav) {
    sidebarNav.classList.toggle('hidden')
  }
}

export { toggleSidebar }
