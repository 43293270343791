const Choices = require('choices.js')

const initChoices = () => {
  const elements = document.querySelectorAll("[data-behavior='choicesjs']");
  // const element = document.getElementById("job_city_id");
  console.log('inside initChoices')
  if (elements.length) {
    console.log('inside initChoices, elements found!')
    elements.forEach(element=> {
      new Choices(element, {
        removeItems: true,
        removeItemButton: true,
        shouldSort: false,
        classNames: {
          containerOuter: 'choices',
          containerInner: 'choices_custom_inner',
          placeholder: 'choices_custom_placeholder',
        }
      });
    })
  }
}

export { initChoices }
