// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "controllers"
import flatpickr from "flatpickr";
import '../stylesheets/application.scss';

import { initChoices } from "../scripts/init-choices.js";
import { toggleSidebar } from "../scripts/toggle-sidebar.js";
import { expandFilter } from "../scripts/expand-filter.js";
import { makeAllSortable } from "../scripts/sortable.js";
import { initTmap } from "../scripts/init-tmap.js.erb";
import { exportXLSX } from "../scripts/export-xlsx.js";

document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behavior='flatpickr']", {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
  });
  flatpickr("[data-behavior='flatpickrdate']", {
    enableTime: false,
  });
  initChoices();
  toggleSidebar();
  expandFilter();
  makeAllSortable();
  initTmap();
  exportXLSX();
})

require("trix")
require("@rails/actiontext")