// import imagePath from 'images/pin.svg'
const initTmap = () => {
  const element = document.getElementById('map')
  
  const imagePath = "https://staging.socialsupply.cn/assets/pin-a10e32cbc1cf909378a790cd2f626fdf6e05d164e61a935353e94c91f47161d9.svg";
  // console.log({imagePath})
  if (element) {
    loadTmap().then(script => {
      console.log('tmap loaded res')
      script.onload = () => {
        console.log('map element found')
        var lat = element.dataset.lat || 31.232866060455322
        var lng = element.dataset.lng || 121.47448338277843
        var marker,markerArr = [],id=0;
        var center = new TMap.LatLng(lat, lng);//设置中心点坐标
        //初始化地图
        var map = new TMap.Map("map", {
            center: center,
            zoom: 12,
            viewMode:'2D'
        });

        markerArr = [{  //向markerArr数组中添加新标注点
              "id": "1",
              "styleId": 'marker',
              "position": new TMap.LatLng(lat, lng),
              "properties": {
                  "title": "marker"
              }
            }]

        // 初始化marker
        var marker = new TMap.MultiMarker({
            id: 'marker-layer',
            map: map,
            styles: {
                "marker": new TMap.MarkerStyle({
                    "width": 50,
                    "height": 50,
                    "anchor": { x: 16, y: 32 },
                    "src": imagePath
                    // "src": 'https://social-supply-mp.oss-cn-shanghai.aliyuncs.com/pin.png'
                })
            },
            geometries: markerArr
        });

        if (element.dataset.type === 'form') {
          //监听点击事件添加marker
          map.on("click", function (evt) {
            console.log('map clicked')
            var latLng = evt.latLng;
            // marker.setMap(null);
            id++;
            marker.updateGeometries({  //向markerArr数组中添加新标注点
                    "id": "1",
                    "styleId": 'marker',
                    "position": latLng
                  })

            console.log(markerArr)
            document.getElementById('event_longitude').value = latLng.lng
            document.getElementById('event_latitude').value = latLng.lat
          })
        }
      }
    })

  }
}

const loadTmap = () => {
  const element = document.getElementById('map')
  if (element) {
    console.log('loading tencent map..')
    return new Promise((resolve, reject) => {
      const apiKey = "4ZTBZ-HTWWP-ZJ3DL-LNGRX-YHFVO-KBFB5"
      // console.log({apiKey})
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${apiKey}&callback=init`;
      document.head.appendChild(script);
      resolve(script)
    })
  }
}

export { initTmap }
