import XLSX from 'xlsx';

const exportXLSX = () => {
  let el = document.getElementById('export-reports')

  if (el) {
    el.addEventListener('click', (e) => {
      console.log(111, e)
      const tableId = e.target.dataset.table
      const table = document.getElementById(tableId);
      generateExcel(table, tableId)
    })
  }
}

const generateExcel = (table, filename) => {
  filename = filename?filename+'.xlsx':'data.xlsx';

  /* Sheet Name */
  const ws_name = "DataSheet";

  // if (typeof console !== 'undefined') console.log(new Date());
  const wb = XLSX.utils.book_new(),

      ws = XLSX.utils.table_to_sheet(table);

  /* Add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  XLSX.writeFile(wb, filename);
}

export { exportXLSX }
