const expandFilter = () => {
  let element = document.getElementById('expandFilter')
  if (element) {
    // console.log('hi from upload js', element)
    element.addEventListener('click', e => {
      const container = document.getElementById('filterContainer')
      container.classList.toggle('block')
      container.classList.toggle('hidden')

      const chevronRight = document.getElementById('chevronRight')
      chevronRight.classList.toggle('block')
      chevronRight.classList.toggle('hidden')

      const chevronDown = document.getElementById('chevronDown')
      chevronDown.classList.toggle('block')
      chevronDown.classList.toggle('hidden')
    })
  }

}

export { expandFilter };
